import React,{ useState, useEffect } from "react";
import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { Header } from "../../components/Header";
import HomeService from "../../api/services/HomeService";
import { alertErrorMessage } from "../../components/CustomAlertMessage";
import LoaderHelper from "../../components/Loading/LoaderHelper";


export const FantasyPointSystem = () => {
  const [t10List, sett10List] = useState([]);
  const [t20List, sett20List] = useState([]);
  const [odiList, setodiList] = useState([]);
  const [testList, settestList] = useState([]);
  const [t20,setT20] = useState(true)
  const [t10,setT10] = useState(false)
  const [odi,setOdi] = useState(false)
  

  useEffect(()=>{
    getPointst20()
  }, [])


  const getPointst20 = async () => {  
    LoaderHelper.loaderStatus(true)
    const result =  await HomeService.getPointst20()
        if (result?.success) { 
          LoaderHelper.loaderStatus(false)         
            try {
              setT20(true)
              setT10(false)
              setOdi(false)
              sett20List(result?.data[0])      
            } catch {
              alertErrorMessage(result.message);
            }
        } else {      
          LoaderHelper.loaderStatus(false)
          alertErrorMessage(result.message);
        }
};

const getPointst10 = async () => {
  LoaderHelper.loaderStatus(true)
  const result = await HomeService.getPoints()
     if (result?.success) {
      LoaderHelper.loaderStatus(false)
      try {
        setT20(false)
        setT10(true)
        setOdi(false)
        sett10List(result?.data[0])         
         } catch {
          alertErrorMessage(result.message);
         }
     } else {
      LoaderHelper.loaderStatus(false)
      alertErrorMessage(result.message);
     }
};

const getPointsODI = async () => {
  LoaderHelper.loaderStatus(true)
  const result = await HomeService.getPointsODI()
      if (result?.success) {
        LoaderHelper.loaderStatus(false)
          try {
            setT20(false)
            setT10(false)
            setOdi(true)
            setodiList(result?.data[0])
          } catch {
            alertErrorMessage(result.message);
          }
      } else {
        LoaderHelper.loaderStatus(false)
        alertErrorMessage(result.message);
      }
};

const getPointstest = async () => {
  LoaderHelper.loaderStatus(true)
  const result = await HomeService.getPointstest()
      if (result?.success) {
        LoaderHelper.loaderStatus(false)
          try {
            setT20(false)
            setT10(false)
            setOdi(false)
            settestList(result?.data[0])
          } catch {
            alertErrorMessage(result.message);
          }
      } else {
        LoaderHelper.loaderStatus(false)
        alertErrorMessage(result.message);
      }
};

const Batting =  (t20 ? t20List : t10 ? t10List : odi ? odiList : testList) .filter(item => item.Catogary == "Batting") 
const Bowling = (t20 ? t20List : t10 ? t10List : odi ? odiList : testList).filter(item => item.Catogary == "Bowling")
const Fielding = (t20 ? t20List : t10 ? t10List : odi ? odiList : testList).filter(item => item.Catogary == "Fielding")
const Other = (t20 ? t20List : t10 ? t10List : odi ? odiList : testList).filter(item=> item.Catogary == "Other")
const Economy = (t20 ? t20List : t10 ? t10List : odi ? odiList : testList).filter(item =>item.Catogary == "EconomyRate" )
const Strike = (t20 ? t20List : t10 ? t10List : odi ? odiList : testList).filter(item =>item.Catogary == "StrikeRate" )


  


  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <Header/>
      {/* <!-- End Header --> */}

      <section className="inner_hero_sec">
        <div className="heading">
          <h1>Fantasy Points System Cricket </h1>
        </div>
      </section>

      <main id="main">
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Fantasy Points System</li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section className="inner-page">
          <div className="container">
            <div className="content">
            <div className="row justify-content-center">
                  <div className="col-lg-9">
                    

                    <div className="points_row" >


                      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="pills-T20-tab" data-bs-toggle="pill" data-bs-target="#pills-T20" type="button" role="tab" aria-controls="pills-T20" aria-selected="true" onClick={getPointst20}>T20</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-ODI-tab" data-bs-toggle="pill" data-bs-target="#pills-ODI" type="button" role="tab" aria-controls="pills-ODI" aria-selected="false" onClick={getPointsODI}>ODI</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-Test-tab" data-bs-toggle="pill" data-bs-target="#pills-Test" type="button" role="tab" aria-controls="pills-Test" aria-selected="false" onClick={getPointstest}>Test</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-T10-tab" data-bs-toggle="pill" data-bs-target="#pills-T10" type="button" role="tab" aria-controls="pills-T10" aria-selected="false" onClick={getPointst10}>T10</button>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                          <button className="nav-link" id="pills-Hundred-tab" data-bs-toggle="pill" data-bs-target="#pills-Hundred" type="button" role="tab" aria-controls="pills-Hundred" aria-selected="false">The Hundred</button>
                        </li> */}
                      </ul>

                      <div className="tab-content pt-3" id="pills-tabContent">
                        <div className="tab-pane  show active">
                          
                        <div className="accordion  custom_accordians " id="accordionExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="bp_1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsebp_1" aria-expanded="false" aria-controls="collapsebp_1">
                              Batting Points
                              </button>
                            </h2>
                            <div id="collapsebp_1" className="accordion-collapse collapse" aria-labelledby="bp_1" data-bs-parent="#accordionExample">
                              
                              <div className="card-body pointBody">
                                  <table className="table pointTable">
                                    <thead>
                                      <tr>
                                        <th scope="col">Type</th>
                                        <th scope="col">Point</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Batting. map(item => (                                       
                                        <React.Fragment key={item?._id}>
                                         <tr>
                                        <td>Run</td>
                                        <td>{item?.Run}</td>
                                      </tr> 
                                      <tr>
                                        <td>Boundary Bonus</td>
                                        <td>{item?.Boundary_Bonus}</td>
                                      </tr>
                                      
                                      <tr>
                                        <td>Six Bonus</td>
                                        <td>{item?.Six_Bonus}</td>
                                      </tr>
                                      {t20 ?
                                      <tr>
                                        <td>30 Runs Bonus</td>
                                        <td>{item?.thirty_run_Bonus}</td>
                                      </tr> : undefined}
                                      <tr>
                                        <td>50 Runs Bonus</td>
                                        <td>{item?.half_century_Bonus}</td>
                                      </tr>
                                      {!t10 ? 
                                      <tr>
                                        <td>Century Bonus</td>
                                        <td>{item?.Century_Bonus}</td>
                                      </tr> : undefined}
                                      <tr>
                                        <td>Dismissal for a duck <br/> <small className="fw-12" >Batsman, Wicket-Keeper & All-Rounder</small> </td>
                                        <td>{item?.Dismissal_on_Duck}</td>
                                      </tr>
                                        </React.Fragment>
                                       
                                      ))}
                                      
                                       
                                    </tbody>
                                  </table>
                                  {/* <p>The dismissal for a duck shall be given to any player coming to the field to bat and getting out scoring 0 runs.</p> */}
                              </div>
                              

                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="bowlp_1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsebowlp_1" aria-expanded="false" aria-controls="collapsebowlp_1">
                              Bowling Points
                              </button>
                            </h2>
                            <div id="collapsebowlp_1" className="accordion-collapse collapse" aria-labelledby="bowlp_1" data-bs-parent="#accordionExample">
                             
                                <div className="card-body pointBody">
                                  <table className="table pointTable">
                                    <thead>
                                      <tr>
                                        <th scope="col">Type</th>
                                        <th scope="col">Point</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Bowling.map(item =>(
                                        <React.Fragment key={item?._id}>
                                         <tr>
                                        <td>Wicket (Excluding run out)</td>
                                        <td>{item?.Wicket}</td>
                                      </tr>
                                      {!t10 ?
                                      <>
                                      <tr>
                                        <td>4-wicket haul bonus</td>
                                        <td>{item?.FourWicketBonus}</td>
                                      </tr>
                                      <tr>
                                        <td>5-wicket haul bonus</td>
                                        <td>{item?.FiveWicket}</td>
                                      </tr> </> : undefined}
                                      {t10 || t20 || odi ? 
                                      <>
                                      <tr>
                                        <td>Maiden over</td>
                                        <td>{item?.Maiden}</td>
                                      </tr>
                                      <tr>
                                        <td>3-wicket haul bonus</td>
                                        <td>{item?.ThreeWicket}</td>
                                      </tr> </> : undefined}
                                      {t10 ? 
                                      <tr>
                                        <td>2-wicket haul bonus</td>
                                        <td>{item?.TwoWicketBonus}</td>
                                      </tr> : undefined}
                                      <tr>
                                        <td>Bonus (LBW/Bowled)</td>
                                        <td>{item?.lbwbonus}</td>
                                      </tr>
                                        </React.Fragment>
                                      ))}
                                     
                                    </tbody>
                                  </table>
                                  {/* <ul>
                                    <li>
                                      <p>No fantasy points will be awarded for a maiden over bowled in <b>The Hundred Cricket Tournament</b>.</p>
                                    </li>
                                    <li>
                                      <p>Wicket (excluding run-out) includes stumped, caught out, and hit wicket. In case of a Run-out, the points for the wicket are given to the person who throws the ball. However, in a case where the batsman is stumped the points are awarded to the wicket keeper and the bowler is awarded points for a wicket.</p>
                                    </li>
                                    <li>
                                      <p>Bonus points for 10-wicket haul will be awarded at the end of the 2nd innings of the match. The points for 10-wicket haul shall only be awarded for Test matches.</p>
                                    </li>
                                    <li>
                                      <p>Any player qualifying for 10-wicket haul along with any other haul like 2-wicket, 3-wicket, 4-wicket or 5-wicket, shall get bonus point for the 10-wicket haul as well as the other qualifying haul.</p>
                                    </li>
                                  </ul> */}
                                </div>

                            </div>
                          </div>
                          
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="fp_1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefp_1" aria-expanded="false" aria-controls="collapsefp_1">
                              Fielding Points
                              </button>
                            </h2>
                            <div id="collapsefp_1" className="accordion-collapse collapse" aria-labelledby="fp_1" data-bs-parent="#accordionExample">

                            <div className="card-body pointBody">
                            <table className="table pointTable">
                                <thead>
                                  <tr>
                                    <th scope="col">Type</th>
                                    <th scope="col">Point</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Fielding.map(item=> (
                                    <React.Fragment key={item?._id}>
                                    <tr>
                                    <td>Catch</td>
                                    <td>{item?.catch}</td>
                                  </tr>
                                  <tr>
                                    <td>Stumping</td>
                                    <td>{item?.stumping}</td>
                                  </tr>
                                  <tr>
                                    <td>Runout (Direct)</td>
                                    <td>{item?.RunOut_throw}</td>
                                  </tr>
                                  <tr>
                                    <td>Runout (Not a direct hit)</td>
                                    <td>{item?.RunOut_catch}</td>
                                  </tr>
                                   {t10 || t20 || odi ? 
                                  <tr>
                                    <td>3 catch haul bonus</td>
                                    <td>{item?.morethenthreecatch}</td>
                                  </tr> : undefined }
                                    </React.Fragment>
                                  ))}
                                  
                                </tbody>
                              </table>
                            </div>

                            </div>
                          </div>
                          
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="sr_1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsesr_1" aria-expanded="false" aria-controls="collapsesr_1">
                              Other
                              </button>
                            </h2>
                            <div id="collapsesr_1" className="accordion-collapse collapse" aria-labelledby="sr_1" data-bs-parent="#accordionExample">
                             
                                <div className="card-body pointBody">
                                  <table className="table pointTable">
                                    <thead>
                                      <tr>
                                        <th scope="col">Type</th>
                                        <th scope="col">Point</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Other.map (item=> (
                                        <React.Fragment key={item?._id}>
                                        <tr>
                                        <td>Captain</td>
                                        <td>{item?.Captain}</td>
                                      </tr>
                                      <tr>
                                        <td>Vice-Captain</td>
                                        <td>{item?.Vice_Captain}</td>
                                      </tr>
                                      <tr>
                                        <td>In announced lineups</td>
                                        <td>{item?.Starting11}</td>
                                      </tr>
                                      <tr>
                                        <td>Playing substitue <br/> <small> (Concussion, COVID-19, X-Factor, or Impact Player)</small></td>
                                        <td>{item?.playingsubstitue}</td>
                                      </tr>
                                        </React.Fragment>
                                      ))}
                                      
                                    </tbody>
                                  </table>
                                   
                                </div>

                            </div>
                          </div>

                          {t10 || t20|| odi ? 
                          <>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="er_1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseer_1" aria-expanded="false" aria-controls="collapseer_1">
                              Economy Rate 
                              <small>Only applicable on Bowlers & All Rounders</small>
                              </button>
                            </h2>
                            <div id="collapseer_1" className="accordion-collapse collapse" aria-labelledby="er_1" data-bs-parent="#accordionExample">
                             
                              <div className="card-body pointBody">
                                <table className="table pointTable">
                                  <thead>
                                    <tr>
                                      <th scope="col">Type</th>
                                      <th scope="col">Point</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Economy.map(item => (
                                      t20 ?
                                      <React.Fragment key={item?._id}>
                                       <tr>
                                      <td>Below 5 runs per over</td>
                                      <td>{item?.FiveRuns}</td>
                                    </tr>
                                    <tr>
                                      <td>Below 5-6.99 runs per over</td>
                                      <td>{item?.FveTosixNine}</td>
                                    </tr>
                                    <tr>
                                      <td>Between 6-7 runs per over</td>
                                      <td>{item?.sixto_seven}</td>
                                    </tr>
                                    <tr>
                                      <td>Between 10-11 runs per over</td>
                                      <td>{item?.TentoEleven}</td>
                                    </tr>
                                    <tr>
                                      <td>Between 11-12 runs per over</td>
                                      <td>{item?.Eleventotwelve}</td>
                                    </tr>
                                    <tr>
                                      <td>Above 12 runs per over</td>
                                      <td>{item?.Abovetwelve}</td>
                                    </tr>
                                      </React.Fragment>
                                     : 
                                     t10 ? 
                                     <React.Fragment key={item?._id}>
                                  <tr>
                                    <td>Below 7 runs per over</td>
                                    <td>{item?.BlwSvn}</td>
                                  </tr>
                                  <tr>
                                    <td>Below 7-7.99 runs per over</td>
                                    <td>{item?.Seven_Seven_pntnin}</td>
                                  </tr>
                                  <tr>
                                    <td>Between 8-9 runs per over</td>
                                    <td>{item?.eight_nine}</td>
                                  </tr>
                                  <tr>
                                    <td>Between 14-15 runs per over</td>
                                    <td>{item?.forteen_fvteen}</td>
                                  </tr>
                                  <tr>
                                    <td>Between 15.1-16 runs per over</td>
                                    <td>{item?.fiftenpntone_sixteen}</td>
                                  </tr>
                                  <tr>
                                    <td>Above 16 runs per over</td>
                                    <td>{item?.sixteen}</td>
                                  </tr>
                                    </React.Fragment>
                                    : 
                                    odi ?
                                    <React.Fragment key={item?._id}>
                                    <tr>
                                   <td>Below 2.5 runs per over</td>
                                   <td>{item?.TwoPNTfive}</td>
                                 </tr>
                                 <tr>
                                   <td>Below 2.5 - 3.5 runs per over</td>
                                   <td>{item?.two_pntfive_thre_pntfive}</td>
                                 </tr>
                                 <tr>
                                   <td>Between 3.5 - 4.5 runs per over</td>
                                   <td>{item?.ThreFiveTOforfive}</td>
                                 </tr>
                                 <tr>
                                   <td>Between 7-8 runs per over</td>
                                   <td>{item?.SevenEight}</td>
                                 </tr>
                                 <tr>
                                   <td>Between 8.01-9 runs per over</td>
                                   <td>{item?.eight_nine}</td>
                                 </tr>
                                 <tr>
                                   <td>Above 9 runs per over</td>
                                   <td>{item?.AboveNine}</td>
                                 </tr>
                                   </React.Fragment> 
                                   : undefined
                                     ))}
                                   
                                  </tbody>
                                </table>
                                <ul>
                                  <li>
                                    <p>The points for Economy Rate will be awarded to all the players but in case of deductions, the points for negative economy rate will be deducted only for all- rounders and bowlers.</p>
                                  </li>
                                  <li>
                                    <p>The economy rate in case of T20 is counted in 2nd over, in case of ODI in 5th over, not counted in case of Test Match &amp; from the first over in case of T10.</p>
                                  </li>
                                </ul>
                              </div>

                            </div>
                          </div>

                          <div className="accordion-item">
                            <h2 className="accordion-header" id="strikerate_1">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsestr_1" aria-expanded="false" aria-controls="collapsestr_1">
                              Strike Rate
                              </button>
                            </h2>
                            <div id="collapsestr_1" className="accordion-collapse collapse" aria-labelledby="strikerate_1" data-bs-parent="#accordionExample">
                             
                                <div className="card-body pointBody">
                                  <table className="table pointTable">
                                    <thead>
                                      <tr>
                                        <th scope="col">Type</th>
                                        <th scope="col">Point</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {Strike.map (item=> (
                                        t20 ? 
                                        <React.Fragment key={item?._id}>
                                        <tr>
                                        <td>Below 50 runs per 100 balls</td>
                                        <td>{item?.Belowfifty}</td>
                                      </tr>
                                      <tr>
                                        <td>Between 50-59 runs per 100 balls</td>
                                        <td>{item?.fiftyTofiftyNine}</td>
                                      </tr>
                                      <tr>
                                        <td>Between 60-70 runs per 100 balls</td>
                                        <td>{item?.sixty_to_seventy}</td>
                                      </tr>
                                      <tr>
                                        <td>Between 130-150 runs per 100 balls</td>
                                        <td>{item?.onethirty_to_one_fifty}</td>
                                      </tr>
                                      <tr>
                                        <td>Between 150-170 runs per 100 balls</td>
                                        <td>{item?.onefiftyto_one_seventy}</td>
                                      </tr>
                                      <tr>
                                        <td>Above 170 runs per 100 balls</td>
                                        <td>{item?.Aboveone_sventy}</td>
                                      </tr>
                                        </React.Fragment>
                                        : t10 ?
                                        <React.Fragment key={item?._id}>
                                        <tr>
                                        <td>Between 70-80 runs per 100 balls</td>
                                        <td>{item?.Seventy_Eighty}</td>
                                      </tr>
                                      <tr>
                                        <td>Between 150-170 runs per 100 balls</td>
                                        <td>{item?.onefiftytooneseventy}</td>
                                      </tr>
                                      <tr>
                                        <td>Between 171-199 runs per 100 balls</td>
                                        <td>{item?.onsvnty_to_oneightynine}</td>
                                      </tr>
                                      <tr>
                                        <td>Over 190 runs per 100 balls</td>
                                        <td>{item?.over_one_ninty}</td>
                                      </tr>
                                      <tr>
                                        <td>Below 60 runs per 100 balls</td>
                                        <td>{item?.Belowsxty}</td>
                                      </tr>
                                        </React.Fragment>
                                        :odi ?
                                        <React.Fragment key={item?._id}>
                                        <tr>
                                        <td>Below 30 runs per 100 balls</td>
                                        <td>{item?.belowthirty}</td>
                                      </tr>
                                      <tr>
                                        <td>Between 30-39.99 runs per 100 balls</td>
                                        <td>{item?.thirty_to_thirty_nine}</td>
                                      </tr>
                                      <tr>
                                        <td>Between 40-50 runs per 100 balls</td>
                                        <td>{item?.fourt_fifty}</td>
                                      </tr>
                                      <tr>
                                        <td>Between 100-120 runs per 100 balls</td>
                                        <td>{item?.one_hndrd_to_one_twenty}</td>
                                      </tr>
                                      <tr>
                                        <td>Between 120.01-140 runs per 100 balls</td>
                                        <td>{item?.one_twenty_to_onefourty}</td>
                                      </tr>
                                      <tr>
                                        <td>Above 140 runs per 100 balls</td>
                                        <td>{item?.above_one_forty}</td>
                                      </tr>
                                        </React.Fragment>
                                        :undefined
                                      ))}
                                      
                                    </tbody>
                                  </table>
                                   
                                </div>

                            </div>
                          </div>
                          </>
                        : undefined }
                        </div>



                        </div>
                        {/* <div className="tab-pane " id="pills-ODI" role="tabpanel" aria-labelledby="pills-ODI-tab"> 
                                  ODI is here
                        </div>
                        <div className="tab-pane " id="pills-Test" role="tabpanel" aria-labelledby="pills-Test-tab">
                                  Test he here
                        </div>
                        <div className="tab-pane " id="T10-Test" role="tabpanel" aria-labelledby="pills-T10-tab">
                                  t10 is here
                        </div>  */}
                      </div>
                    </div>





                  </div>
                </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
