const appUrl = "https://fs11admin.fantasyscore11.com";

export const ApiConfig = {
  getPointstest: "PointsTBL/getPointstest",
  getPoints: "PointsTBL/getPoints",
  getPointst20: "PointsTBL/getPointst20",
  getPointsODI: "PointsTBL/getPointsODI",
  



  // ................... //
  appUrl: `${appUrl}/`,
}
