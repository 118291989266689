import { Footer } from "../../components/Footer";
import { Header } from "../../components/Header";
import { Link } from "react-router-dom";

export const Contact = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <Header/>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>Contact Us</h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Contact Us</li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section id="contact" class="contact">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>Contact</h2>
              <p>Contact Us</p>
            </div>

            <div class="row align-items-start">
              <div class="col-lg-12" data-aos="fade-right" data-aos-delay="100">
                <div class="info row align-items-start">
                  <div class="email  col-md-4 mt-md-0">
                    <i class="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>support@fantasyscore11.com</p>
                  </div>

                  <div class="phone  col-md-4  mt-md-0">
                    <i class="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>+91  7878 107 701</p>
                  </div>
                  <div class="address col-md-4">
                    <i class="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>P.NO - 30, Hanman extention, Jaipur-302012 RAJ.</p>
                  </div>
                </div>
              </div>

              {/* <div
                class="col-lg-8 mt-5 mt-lg-0"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <form
                  action="forms/contact.php"
                  method="post"
                  role="form"
                  class="php-email-form"
                >
                  <div class="row">
                    <div class="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div class="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div class="form-group mt-3">
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div class="form-group mt-3">
                    <textarea
                      class="form-control"
                      name="message"
                      rows="5"
                      placeholder="Message"
                      required
                    ></textarea>
                  </div>
                  <div class="my-3">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div> */}
            </div>
          </div>
        </section>
        {/* <!-- End Contact Section --> */}
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
