import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { Header } from "../../components/Header";


export const HowToPlay = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
     <Header/>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>How To Play</h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>How To Play</li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              The Fantasy Score 11 users are enforced to follow certain Terms &
              Conditions associated with the usage of Fantasy Score 11. It is
              mandatory for the users to follow the terms and conditions of
              Fantasy Score 11 while using the services provided by Fantasy
              Score 11:
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
