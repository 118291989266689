import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { About } from "./ui/About";
import { Contact } from "./ui/Contact";
import { FairPlay } from "./ui/FairPlay";
import { HowToPlay } from "./ui/HowToPlay";
import { Policy } from "./ui/Policy";
import { TermsNConditions } from "./ui/TermsNConditions";
import { Home } from "./ui/Home";
import { Legalities } from "./ui/Legalities";
import { ResponsibleGaming } from "./ui/ResponsibleGaming";
import { FantasyPointSystem } from "./ui/FantasyPointSystem";
import { ToastContainer } from "react-toastify";
import Loading from "./components/Loading";
import LoaderHelper from "./components/Loading/LoaderHelper";

function App() {
  return (
    <Router>
      <Loading ref={ref => LoaderHelper.setLoader(ref)} />
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss={false} draggable pauseOnHover limit={1} theme="light" />
   
      <Routes>     
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/fairPlay" element={<FairPlay />} />
        <Route path="/howToPlay" element={<HowToPlay />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/termsNConditions" element={<TermsNConditions />} />
        <Route path="/legalities" element={<Legalities />} />
        <Route path="/responsible_gaming" element={<ResponsibleGaming />} />
        <Route path="/fantasy_points_system" element={<FantasyPointSystem />} />
      </Routes>
   
     </Router>
  );
}

export default App;
