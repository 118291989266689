import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { Header } from "../../components/Header";

export const FairPlay = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <Header/>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>Fair Play Policy </h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Fair Play</li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content">
              <div className="row" >
                  <div class="col-md-12">
                    <h4>Fair Play Violation</h4>
                    <p>Fantasy Score 11 considers Fair Play to be a priority at its platform and any user violating the Fair Play Terms of Fantasy Score 11 shall be entitled to bear various consequences including Blocking of User’s Fantasy Score 11 Account.</p>
                    <h4>Fair Play Violation at Fantasy Score 11 includes:</h4>
                    <p>The people you are referring should be genuine accounts and the user should not use any fake accounts to gain the 'Refer &amp; Earn' Cash Bonus.</p>
                    <p>The PAN card of all your referred friends should be verified. In an event, the PAN card of more than 9 of your referred friends are not verified, the user will be considered as violating the Fair Play Terms of Fantasy Score 11.</p>
                    <p>A user creating more than one account on Fantasy Score 11 platform will be considered as violating the Fair Play Terms of Fantasy Score 11 as Fantasy Score 11 neither allows nor encourages any user to create more than one user account on Fantasy Score 11.</p>
                    <p>Withdrawing your winnings by using any unlawful or illegal means or trying to withdraw winnings by illegal manners is considered as Fair Play violation at Fantasy Score 11.</p>
                    <p>If a user is found submitting fake copies or someone else’s documents while registering with Fantasy Score 11, the act will be considered as violating the Fair Play Terms of Fantasy Score 11.</p>
                    <p>In case a user is found to be creating and using multiple accounts on a single device amid regular platform audits, then, the case shall be considered to violate Fair Play Terms of Fantasy Score 11 and attract needed actions. As a part of the action, the withdrawal request of the user shall be put on hold.</p>
                    <h4>Consequences of Violating Fair Play Terms of Fantasy Score 11</h4>
                    <p>As a part of consequences for violating the terms of Fair Play at Fantasy Score 11, the account of the user found to be violating the Fair Play terms will be blocked.</p>
                    <p>Further, in other scenario the total amount present in the Fantasy Score 11 user account of the user violating the Fair Play terms of Fantasy Score 11 will be reduced to zero.</p>
                    <p>In case, a user is using a single device for creating multiple accounts on Fantasy Score 11, it should be ensured by the user that all the mandatory verification formalities, including mobile, email, PAN card &amp; Bank Account details verification have been done by the user for all the accounts created. Abiding by the said process will help such users, who create multiple accounts on a single device, in terms of smooth withdrawals on Fantasy Score 11.</p>
                  </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
