import { Link } from "react-router-dom"

export const Header = () =>{

  

  const apkFileUrl = 'https://fs11admin.fantasyscore11.com/downloads';

    return(
      <header id="header" class="d-flex align-items-center header-transparent">
        <div class="container d-flex align-items-start justify-content-between">
          <div class="logo">
           
            <Link to="/">
              <img src="img/logo.png" alt="" class="img-fluid" />{" "}
            </Link>
          </div>

          <nav id="navbar" class="navbar">
            <ul>
              <li className="dn_row" >                
                <a href={apkFileUrl} className="nav-link btn btn-light scrollto">
                  <i class="ri-download-fill me-2"></i> Download App 
                </a>
                <small className="get_bonus" >Get ₹100 Bonus</small>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    )
}