import { Footer } from "../../components/Footer";
import { Link } from "react-router-dom";
import { Header } from "../../components/Header";


export const Policy = () => {
  return (
    <>
      {/* <!-- ======= Header ======= --> */}
      <Header/>
      {/* <!-- End Header --> */}

      <section class="inner_hero_sec">
        <div class="heading">
          <h1>Privacy Policy</h1>
        </div>
      </section>

      <main id="main">
        <section class="breadcrumbs">
          <div class="container">
            <div class="d-flex justify-content-between align-items-center">
              <ol>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>Privacy Policy</li>
              </ol>
            </div>
          </div>
        </section>
        {/* <!-- End Breadcrumbs Section --> */}

        <section class="inner-page">
          <div class="container">
            <div class="content_policy">
              <h3>Privacy Policy</h3>
              <p class="lh18 pdTop15">
                Sporta Technologies Private Limited operates the portal in
                India, which offers cricket, football, basketball, baseball,
                esports, volleyball, handball, hockey, kabaddi, and other
                sporting event fantasy games through the web-portal Fantasy
                Score 11.com and partner website(s) and mobile application(s)
                (collectively referred to as the “Portal”). Sporta Technologies
                Private Limited referred to herein as “
                <strong>Fantasy Score 11</strong>” or “<strong>we</strong>” or “
                <strong>us</strong>” “<strong>our</strong>”.
              </p>
              <p class="lh18 pdTop15">
                Any person utilizing the Portal ("<strong>User</strong>" or "
                <strong>you</strong>" or "<strong>your</strong>") or any of its
                features including participation in the various contests, games
                (including fantasy games ("<strong>Game</strong>") ("
                <strong>Services</strong>") being conducted on the Portal shall
                be bound by the terms of this privacy policy (“
                <strong>Privacy Policy</strong>”).{" "}
              </p>
              <p class="lh18 pdTop15">
                All capitalised terms not defined herein shall have the meaning
                ascribed to them in the Terms.{" "}
              </p>
              <p class="lh18 pdTop15">
                Fantasy Score 11 respects the privacy of its Users and is
                committed to protect it in all respects. With a view to offer an
                enriching and holistic internet experience to its Users, Fantasy
                Score 11 offers a vast repository of Services. Kindly take time
                to read the 'About Us' section to know more about Fantasy Score
                11. Most of the Services are offered for free but you may need
                registration to participate in the Games. The information about
                the User is collected by Fantasy Score 11 as (i) information
                supplied by Users and (ii) information automatically tracked
                during User's navigation on Fantasy Score 11.
              </p>
              <p class="lh18 pdTop15">
                Before you submit any information to the Portal, please read
                this Privacy Policy for an explanation of how we will treat your
                information. By using any part of the Portal, you consent to the
                collection, use, disclosure and transfer of your information for
                the purposes outlined in this Privacy Policy and to the
                collection, processing and maintenance of this information. Your
                use of any part of the Portal indicates your acceptance of this
                Privacy Policy and of the collection, use and disclosure of your
                information in accordance with this Privacy Policy. While you
                have the option not to provide us with certain personal
                information, withdraw your consent to collect certain
                information, request temporary suspension of collection of
                personal information or request deletion of personal information
                collected, kindly note that in such an event you may not be able
                to take full advantage of the entire scope of features and
                services offered to you and we reserve the right not to provide
                you with our services.
              </p>
              <p class="lh18 pdTop15">
                In the ever-evolving medium of the internet, We may periodically
                review and change our Privacy Policy to incorporate such future
                changes as may be considered appropriate. We will notify you of
                the change. Any changes to our Privacy Policy will be posted on
                this page, so you are always aware of what information we
                collect, how we use it, how we store it and under what
                circumstances we disclose it.
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>Purpose and Usage:</strong>
              </p>
              <p class="lh18 pdTop15">
                To avail certain Services on the Portal, Users would be required
                to provide certain information for the registration process,
                namely:
              </p>
              <p class="lh18 pdTop15">
                1. Username
                <br />
                2. Email address&nbsp;
                <br />
                3. Date of birth
                <br />
                4. State
                <br />
                5. Government ID such as aadhaar card or driving license or
                voter id&nbsp;
              </p>
              <p class="lh18 pdTop15">
                Additionally in the course of providing you with access to the
                Services, and in order to provide you access to the features
                offered through the Portal and to verify your identity, secure
                your account details, you may give us the permission to capture,
                record your device related information, operating system
                information, network information, location information etc. You
                may choose to allow us to gather information in relation to the
                apps installed on your device in order for us to better our
                Services and your experience on the Portal. You may also be
                required to furnish additional information, including your
                Permanent Account Number.{" "}
              </p>
              <p class="lh18 pdTop15">
                In certain instances, we may also collect sensitive personal
                information from you on the Portal. SPI means such personal
                information which consists of information relating to your
                financial information, such as information regarding the payment
                instrument/modes used by you to make such payments, which may
                include cardholder name, credit/debit card number (in encrypted
                form) with expiration date, banking details, wallet details etc
                (“SPI”) This information is presented to you at the time of
                making a payment to enable you to complete your payment
                expeditiously.{" "}
              </p>
              <p class="lh18 pdTop15">
                Except for any financial information that you choose to provide
                while making payment for any Services on the Portal, Fantasy
                Score 11 does not collect any other SPI in the course of
                providing the Services. Any SPI collected by Fantasy Score 11
                shall not be disclosed to any third-party service provider
                without your express consent, save as otherwise set out in this
                Privacy Policy or as provided in a separate written agreement
                between Fantasy Score 11 and you or as required by law. It is
                clarified that this condition shall not apply to publicly
                available information, including SPI, in relation to you on the
                Portal.{" "}
              </p>
              <p class="lh18 pdTop15">
                We collect and use personal information provided by you (that
                is, information that may be used to identify you and that is not
                otherwise publicly available) to operate, provide, develop, and
                improve the Services, keeping you informed of Our offerings, and
                the products and/or services of our affiliates and group
                entities, and to enable Our affiliates and group entities to
                reach out to you in relation to the products and services
                offered by them.
              </p>
              <p class="lh18 pdTop15">
                In the course of providing the Services, Users may invite other
                existing Users or other users ("<strong>Invited Users</strong>")
                to participate in any of the Services through any social media
                platform including without limitation, Facebook, WhatsApp,
                Instagram etc. We may thereafter use this information to contact
                the Invited User and invite such user to register with Fantasy
                Score 11 (if such Invited User is not an existing User) and
                participate in the Game in relation to which such person was
                invited by you. The participation of the Invited User in any
                Game shall be subject to the terms of this Privacy Policy and
                the Terms and Conditions for the use of the Portal. You hereby
                represent that the Invited Users have consented and agreed to
                such disclosure of their information with us, our affiliates
                and/ or our group entities. You may also choose to invite your
                friends by syncing your phonebook and inviting them directly
                from the Portal to play the Game and utilize the Services.
              </p>
              <p class="lh18 pdTop15">
                All required information is specific and based on the kind of
                Game/ Services the User wishes to participate in or access, and
                will be utilized to provide services, including but not limited
                to the Services requested by the User.
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>Disclosure/Sharing:</strong>
              </p>
              <p class="lh18 pdTop15">
                Fantasy Score 11 may share information as provided by you and
                data concerning your usage of the Services and participation in
                the Games with its affiliates and group entities to enable its
                affiliates and group entities to reach out to you in relation to
                the products and/ or services offered by them. Fantasy Score 11
                may also share such information with third party service
                providers engaged by Fantasy Score 11, for the purpose of data
                analytics or for the purpose of storage, improving the services
                (including product enhancements) and to help Fantasy Score 11
                serve you better. Provided that affiliate, group entity and
                third-party service provider (as may be applicable) with whom
                the information is shared will be required, on best efforts
                basis, to comply with the same standards as applicable to
                Fantasy Score 11 in relation to maintaining the security of the
                information.{" "}
              </p>
              <p class="lh18 pdTop15">
                By using the Portal, you hereby expressly agree and grant
                consent to the collection, use and storage of your information
                by Fantasy Score 11. Fantasy Score 11 reserves the right to
                share, disclose and transfer information collected hereunder
                with its affiliates and group entities. In the event Fantasy
                Score 11 sells or transfers all or a portion of its business
                assets, consumer information may be one of the business assets
                that are shared, disclosed or transferred as part of the
                transaction. You hereby expressly grant consent and permission
                to Fantasy Score 11 for disclosure and transfer of personal
                information to such third parties.{" "}
              </p>
              <p class="lh18 pdTop15">
                Fantasy Score 11 may share information and data concerning usage
                of the Services and participation in the Game with its
                commercial partners for the purpose of facilitating user
                engagement, for marketing and promotional purposes and other
                related purposes. Further, Fantasy Score 11 reserves the right
                to disclose/ share information with affiliates, group entities
                and third parties in limited circumstances, including for the
                purposes of complying with applicable law, responding to duly
                authorized legal process, governmental requests, preventing
                fraud or imminent harm, and ensuring the security of our network
                and services.{" "}
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>Use of Cookies:</strong>
              </p>
              <p class="lh18 pdTop15">
                To improve the effectiveness and usability of the Portal for our
                Users, we use "cookies", or such similar electronic tools to
                collect information to assign each visitor a unique random
                number as a User Identification (User ID) to understand the
                User's individual interests using the identified device. Unless
                the User voluntarily identifies himself/herself (e.g., through
                registration), Fantasy Score 11 has no way of knowing who the
                User is, even if we assign a cookie to the User's device. The
                only information a cookie can contain is information supplied by
                the User. A cookie cannot read data off the User's device hard
                drive. Fantasy Score 11’s advertisers may also assign their own
                cookies to the User's device (if the User clicks on their ad
                banners), a process that Fantasy Score 11 does not control.
              </p>
              <p class="lh18 pdTop15">
                Fantasy Score 11's web servers automatically collect limited
                information about User's device connection to the Internet,
                including User's IP address, when the User uses the Portal.
                (User's IP address is a number that lets devices attached to the
                Internet know where to send data to the User -- such as the web
                pages viewed by the User). The User's IP address does not
                identify the User personally. Fantasy Score 11 uses this
                information to deliver its web pages to Users upon request, to
                tailor its Portal to the interests of its users, to measure
                traffic within the Portal and let advertisers know the
                geographic locations from where Fantasy Score 11's visitors
                come.
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>Links:</strong>
              </p>
              <p class="lh18 pdTop15">
                Fantasy Score 11 also includes links to other websites. Such
                websites are governed by their respective privacy policies,
                which are beyond Fantasy Score 11's control. Once the User
                leaves Fantasy Score 11's servers (the User can tell where
                he/she is by checking the URL in the location bar on the User's
                browser), use of any information provided by the User is
                governed by the privacy policy of the operator of the site which
                the User is visiting. That policy may differ from Fantasy Score
                11's own. If the User can't find the privacy policy of any of
                these sites via a link from the site's homepage, the User may
                contact the site directly for more information. Fantasy Score 11
                is not responsible for the privacy practices or the content of
                such websites.
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>Security Procedures:</strong>
              </p>
              <p class="lh18 pdTop15">
                All information gathered on Fantasy Score 11 is securely stored
                within the Fantasy Score 11-controlled database. The database is
                stored on servers secured behind a firewall; access to such
                servers being password-protected and strictly limited based on
                need-to-know basis. However, we understand that as effective as
                our security measures are, no security system is impenetrable.
                Thus, we cannot guarantee the security of our database, nor can
                we guarantee that information you supply will not be intercepted
                while being transmitted to us over the Internet. Further, any
                information you include in a posting to the discussion areas
                will be available to anyone with internet access. By using the
                Portal, you understand and agree that your information may be
                used in or transferred to countries other than India.{" "}
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>Advertising:</strong>
              </p>
              <p class="lh18 pdTop15">
                When Fantasy Score 11 presents information to its online
                advertisers - to help them understand its audience and confirm
                the value of advertising on the Portal - it is usually in the
                form of aggregated anonymized data (information that cannot be
                used to trace a user) of Users that may include statistics
                and/or data concerning User traffic, User responsiveness and
                User behaviour to such advertisements on our Portal. When you
                register with Fantasy Score 11, we contact you from time to time
                about updating your content to provide features which we believe
                may benefit you.
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>Disclaimer:</strong>
              </p>
              <p class="lh18 pdTop15">
                Several deceptive emails, websites, blogs etc. claiming to be
                from or associated with Fantasy Score 11 may or are circulating
                on the Internet (“<strong>Deceptive Communication</strong>”).
                Such Deceptive Communication may include our logo, photos,
                links, content or other information. Some Deceptive
                Communication may call the User requesting the User to provide
                login name, password etc. or inform the User that the User has
                won a prize/ gift or provide a method to commit an illegal/
                unauthorized act or deed or request detailed personal
                information or a payment of some kind. The sources and contents
                of these Deceptive Communications and accompanying materials are
                in no way associated with Fantasy Score 11. For your own
                protection, we strongly recommend not responding to such
                Deceptive Communication. You agree that we shall not be liable
                for any loss, damage and harm that you may suffer by relying
                and/or acting upon such Deceptive Communications.
              </p>
              <p class="lh18 pdTop15">
                Except, as provided for the purpose of verification, as set out
                under the Terms and Conditions, we and/or our employees will not
                contact you to divulge your SPI.
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>Conditions of Use:</strong>
              </p>
              <p class="lh18 pdTop15">
                Fantasy Score 11 DOES NOT WARRANT THAT THIS PORTAL, IT’S
                SERVERS, OR EMAIL SENT BY US OR ON OUR BEHALF ARE VIRUS FREE.
                Fantasy Score 11 WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
                ARISING FROM THE USE OF THIS PORTAL, INCLUDING, BUT NOT LIMITED
                TO COMPENSATORY, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL
                AND CONSEQUENTIAL DAMAGES, LOSS OF DATA, GOODWILL, BUSINESS
                OPPORTUNITY, INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY AND
                CLAIMS OF THIRD PARTIES. IN NO EVENT WILL Fantasy Score 11 BE
                LIABLE FOR ANY DAMAGES WHATSOEVER IN AN AMOUNT IN EXCESS OF AN
                AMOUNT OF INR 100.
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>Retention of Data:</strong>
              </p>
              <p class="lh18 pdTop15">
                Your personal information may be retained and may continue to be
                used until: (i) the relevant purposes for the use of your
                personal information described in this Privacy Policy are no
                longer applicable; and (ii) we are no longer required by
                applicable law, regulations, contractual obligations or
                legitimate business purposes to retain your personal information
                and the retention of your personal information is not required
                for the establishment, exercise or defense of any legal claim.
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>User Account and Data Deletion:</strong>
              </p>
              <p class="lh18 pdTop15">
                Users are entitled to request Fantasy Score 11 to delete their
                User accounts and their personal information by sending an email
                with their written request to{" "}
                <a
                  href="mailto:support@fantasyscore11.com"
                  target="_blank"
                >
                  <span class="s1">support@fantasyscore11.com</span>
                </a>
              </p>
              <p class="lh18 pdTop15">
                We will do our best to respond promptly and in any event within
                one month of the following:{" "}
              </p>
              <ul>
                <li>Our receipt of your written request; or</li>
                <li>
                  Our receipt of any further information we may ask you to
                  provide, to enable us to comply with your request, whichever
                  is later.
                </li>
              </ul>
              <p></p>
              <p class="lh18 pdTop15">
                As an alternative to account deletion, by writing to{" "}
                <a href="mailto:support@fantasyscore11.com">
                  <span class="s1">support@fantasyscore11.com</span>
                </a>
                , you also have the option to request either:{" "}
              </p>
              <ul>
                <li>
                  (i) the suspension of your account, after which you will not
                  be able to play paid contests on your User account but will
                  continue to have access to other parts of the Portal; or
                </li>
                <li>
                  (ii) temporary deactivation of your account, where you will no
                  longer be able to log into the Portal but which allows you to
                  request reactivation of your account with all your account
                  data.
                </li>
              </ul>
              <p></p>
              <p class="lh18 pdTop15">
                If you proceed with permanent deactivation of your account, you
                will lose access to the Portal and the Services, including any
                User data and personal information associated with your account.
                Users may request for account restoration within six (6) months
                from the date of notification of account deletion by Fantasy
                Score 11 by writing to{" "}
                <a href="mailto:support@fantasyscore11.com">
                  <span class="s1">support@fantasyscore11.com</span>
                </a>
              </p>
              <p class="lh18 pdTop15">
                When you request deletion of your data, we follow a deletion
                process that ensures that your data is safely and completely
                removed from our servers or retained only in anonymised form. We
                try to ensure that our services protect information from
                accidental or malicious deletion. Because of this, there may be
                delays between when you request deletion and when copies are
                deleted from our active and backup systems.
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>Applicable Law and Jurisdiction:</strong>
              </p>
              <p class="lh18 pdTop15">
                By visiting this Portal, you agree that the laws of the Republic
                of India without regard to its conflict of laws principles,
                govern this Privacy Policy and any dispute arising in respect
                hereof shall be subject to and governed by the dispute
                resolution process set out in the{" "}
                <a
                  href="https://fantasyscore11.com/termsNConditions"
                  target="_blank"
                >
                  Terms and Conditions
                </a>
                .{" "}
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>Updating Information:</strong>
              </p>
              <p class="lh18 pdTop15">
                You will promptly notify Fantasy Score 11 if there are any
                changes, updates or modifications to your personal information.
                Further, you may also review, update or modify your personal
                information and User preferences by logging into your profile
                page on the Portal.{" "}
              </p>
              <p class="lh18 pdTop15 mb0">
                <strong>Contact Us:</strong>
              </p>
              <p class="lh18 pdTop15">
                Any questions or clarifications with respect to this Privacy
                Policy or any complaints, comments, concerns or feedback can be
                sent to Fantasy Score 11 at:{" "}
                <a href="mailto:support@fantasyscore11.com">
                  <span class="s1">support@fantasyscore11.com</span>
                </a>
                &nbsp;or by normal/physical mail addressed to:
              </p>
              <p class="lh18 pdTop15">Attn: Fantasy Score 11 Team</p>
              <p class="lh18 pdTop15">
                30, Hanman extention, Jaipur-302012 RAJ.
                <br />
                6350406032
                <br />
                support@fantasyscore11.com
              </p>
            </div>
          </div>
        </section>
      </main>
      {/* <!-- End #main --> */}

      {/* <!-- ======= Footer ======= --> */}
      <Footer />
      {/* <!-- End Footer --> */}

      <a
        href="#"
        class="back-to-top d-flex align-items-center justify-content-center"
      >
        <i class="bi bi-arrow-up-short"></i>
      </a>
      {/* <div id="preloader"></div> */}
    </>
  );
};
